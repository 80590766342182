import { configureStore } from '@reduxjs/toolkit';
import propertyInfoReducer from 'store/Slices/PropertyInfo';
import mortgageReducer from 'store/Slices/Mortgage';
import applicantsReducer from 'store/Slices/Applicants';
import sidebardReducer from 'store/Slices/Sidebar';

export const store = configureStore({
  reducer: {
    propertyInfo: propertyInfoReducer,
    mortgage: mortgageReducer,
    applicants: applicantsReducer,
    sidebar: sidebardReducer
  }
});
