import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import statesJSON from './state.json';
import citiesJSON from './cities.json';
import { filter, find } from 'lodash';
import moment from 'moment';

const isUpperCase = (string) => /[A-Z]/.test(string);
const isLowerCase = (string) => /[a-z]/.test(string);
const isNumber = (string) => /[0-9]/.test(string);
const isSpecialCharacter = (string) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(string);
const minEightCharacter = (string) => /[^ ]{8,16}/.test(string);
const LOG_IN = 'Log In';
const SIGN_UP = 'Sign Up';

const getCanadaStates = () => {
  return statesJSON;
};

const getCitiesByState = (provinceName) => {
  const cities = filter(citiesJSON, (item) => item.stateName === provinceName);
  return cities;
};

const updateFormObj = (obj) => {
  const copy = { ...obj };
  for (let key in obj) {
    let value = obj[key];
    if (value && (value === 'false' || value === 'true')) {
      copy[key] = /^\s*(true|1|on)\s*$/i.test(value);
    } else {
      copy[key] = value;
    }
  }
  return copy;
};

const getPaginationList = (num) => {
  let arry = [];
  for (let page = 0; page < num; page++) {
    arry.push(page + 1);
  }
  return arry;
};

const suffixOfNumber = (number) => {
  return [('st', 'nd', 'rd')][((((number + 90) % 100) - 10) % 10) - 1] || 'th';
};

// Using custom test method
function validateNumber(message) {
  return this.test('validateNumber', message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: 'Required' });
    }

    if (!isValidPhoneNumber(value)) {
      return createError({ path, message: 'Phone Number is not valid' });
    }

    return true;
  });
}

const currencyFormat = (value) => {
  if (!value) return '-';
  if (isNaN(value)) return '-';
  let num = Number(value);
  const format = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const splitByDot = format.split('.');
  const newNumber = splitByDot[1];
  if (!Number(newNumber)) {
    return `$${splitByDot[0]}`;
  }
  return num ? `$${format}` : '-';
};

const findRoleById = (id, data) => {
  if (!id || !data) return '';
  const {
    rolesList: { roles }
  } = data;
  const roleKey = find(roles, (item) => item.id === parseInt(id));
  return roleKey?.title;
};

const getTotalPage = (totalItems, pageSize) => {
  const totalPage = totalItems / pageSize;
  const page = Math.floor(totalItems / pageSize);
  const splitted = totalPage.toString().split('.');
  if (splitted[1]) {
    return page + 1 - 1;
  } else {
    return page - 1;
  }
};

const formatAmount = (value) => {
  if (value >= 1000000 || value <= -1000000) {
    value = value / 1000000;
    const newVal = value.toFixed(1);
    return `$${newVal}M`;
  } else if (value >= 1000 || value <= -1000) {
    value = value / 1000;
    const newVal = value.toFixed(1);
    const splitByDot = newVal.split('.');
    const newNumber = splitByDot[1];
    if (!Number(newNumber)) {
      return `$${splitByDot[0]}K`;
    }
    return `$${newVal}K`;
  }
  if (value) {
    return `$${value}`;
  }
  return value;
};

const formatContactNumber = (number) => {
  const formatted = formatPhoneNumberIntl(number);
  const splittedNum = formatted.split(' ');

  if (splittedNum.length === 4) {
    return `${splittedNum[0]} (${splittedNum[1]}) ${splittedNum[2]}-${splittedNum[3]}`;
  }
  return formatted;
};

const getDateTimeAfter5Day = (date) => {
  const todayDate = moment(new Date());
  const newDate = moment(new Date(Number(date)));
  const diff = todayDate.diff(newDate, 'days');
  if (diff < 28) {
    return moment(newDate, 'YYYYMMDD').fromNow();
  } else {
    return moment(newDate).format('ll');
  }
};

const dateTimeAgo = (date) => {
  const newDate = new Date(date);
  if (typeof newDate?.getMonth !== 'function') return null;
};

export {
  isUpperCase,
  isLowerCase,
  isNumber,
  isSpecialCharacter,
  minEightCharacter,
  LOG_IN,
  SIGN_UP,
  updateFormObj,
  getPaginationList,
  suffixOfNumber,
  getCanadaStates,
  getCitiesByState,
  validateNumber,
  currencyFormat,
  findRoleById,
  getTotalPage,
  formatAmount,
  formatContactNumber,
  getDateTimeAfter5Day
};
